.footer {

	padding: px-to-vw(63px) px-to-vw(42px) px-to-vw(98px) px-to-vw(62px);
    background: $gray-light;
    border-radius: px-to-vw(50px) px-to-vw(50px) 0 0;
    color: $gray;
    font-style: normal;
    font-weight: 400;
    font-size: px-to-vw(14px);
    line-height: px-to-percentage(28px);

	&__content {
		margin-bottom: px-to-vw(30px);
        font-size: px-to-vw(18px);
        line-height: px-to-vw(26px);
	}

	&__apps {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: px-to-vw(30px);

		&-item {

			font-weight: 600;
			margin-right: px-to-vw(4px);

			&-break {
				flex-basis: 100%;
				display: none;
			}

			&:first-child {
				margin-right: px-to-vw(40px);
			}

			&:last-child {
				margin-right: 0px;
			}

			&.--icon-appstore {
				width: px-to-vw(85px);
				height: px-to-vw(30px);
				background: url('../img/sprite.svg#sprite-icon-appstore-view') no-repeat;
			}

			&.--icon-googleplay {
				width: px-to-vw(99px);
				height: px-to-vw(30px);
				background: url('../img/sprite.svg#sprite-icon-googleplay-view') no-repeat;
			}

			&.--icon-appgallery {
				width: px-to-vw(101px);
				height: px-to-vw(30px);
				background: url('../img/sprite.svg#sprite-icon-appgallery-view') no-repeat;
			}

			&.--icon-apk {
				width: px-to-vw(99px);
				height: px-to-vw(30px);
				background: url('../img/sprite.svg#sprite-icon-apk-view') no-repeat;
			}

			& a {

				display: block;
				width: 100%;
				height: 100%;

				& picture,
				& picture img {
					display: inline-block;
				}

			}

		}

	}

	&__copyright {

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		& a {

			text-decoration: none;
            color: $gray;
			display: inline-block;

			&:hover {
				text-decoration: underline;
			}

		}

		&-left {

		}

		&-right {

			margin-left: auto;

            & a:first-child {

                margin-right: px-to-vw(10px);
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -2px;
                    right: px-to-vw(-8px);
                    width: px-to-vw(1px);
                    height: 100%;
                    background-color: $gray;
                }

            }

		}

	}

}

@media screen and (max-width: 960px) {

	.footer {

		padding: px-to-vw-mobile(20px) px-to-vw-mobile(20px) px-to-vw-mobile(30px) px-to-vw-mobile(16px);
		border-radius: px-to-vw-mobile(25px) px-to-vw-mobile(25px) 0 0;
		font-size: px-to-vw-mobile(14px);
		line-height: px-to-percentage-mobile(28px);
	
		&__content {
			margin-bottom: px-to-vw-mobile(20px);
			font-size: px-to-vw-mobile(18px);
			line-height: px-to-vw-mobile(26px);
		}
	
		&__apps {
	
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			margin-bottom: px-to-vw-mobile(20px);
	
			&-item {
	
				margin-right: 0;
				margin-bottom: px-to-vw-mobile(13px);
	
				&-break {
					flex-basis: 100%;
					display: none;
				}
	
				&:first-child {
					margin-right: 0;
					margin-bottom: px-to-vw-mobile(13px);
				}
	
				&:last-child {
					margin-bottom: 0px;
				}
	
				&.--icon-appstore {
					width: px-to-vw-mobile(85px);
					height: px-to-vw-mobile(30px);
				}
	
				&.--icon-googleplay {
					width: px-to-vw-mobile(99px);
					height: px-to-vw-mobile(30px);
				}
	
				&.--icon-appgallery {
					width: px-to-vw-mobile(101px);
					height: px-to-vw-mobile(30px);
				}
	
				&.--icon-apk {
					width: px-to-vw-mobile(99px);
					height: px-to-vw-mobile(30px);
				}
	
			}
	
		}
	
		&__copyright {
	
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
	
			& a {
	
				text-decoration: none;
				color: $gray;
				display: inline-block;
	
				&:hover {
					text-decoration: underline;
				}
	
			}
	
			&-left {
				order: 1;
			}
	
			&-right {

				order: 0;
				margin-bottom: px-to-vw-mobile(20px);
	
				& a:first-child {
	
					margin-right: 0;
					margin-bottom: px-to-vw-mobile(5px);
					position: relative;
	
					&:after {
						display: none;
					}
	
				}
	
			}
	
		}
	
	}

}