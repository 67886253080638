@font-face {
    font-family: 'SB Sans Display';
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Thin.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Thin.svg') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SB Sans Display';
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Light.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Light.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Light.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Light.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SB Sans Display';
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Regular.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Regular.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Regular.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Regular.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SB Sans Display';
    src: url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-SemiBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SB Sans Display';
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Bold.eot');
    src: url('../fonts/SBSansDisplay/SBSansDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Bold.woff2') format('woff2'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Bold.woff') format('woff'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Bold.ttf') format('truetype'),
        url('../fonts/SBSansDisplay/SBSansDisplay-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}
