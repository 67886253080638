$base-screen-width: 1440px;

$white: #FFFFFF;
$black: #000000;
$gray-light: #F5F5F5;
$gray: #838383;
$gray-dark: #333333;
$green: #21A038;
$green-light: #25B53F;

$body-bg: $white;

$standart-text-color: $black;
$standart-text-light-color: $white;

$standart-text-color-mobile: $gray-dark;
$standart-text-light-color-mobile: $white;

$sbm-button-color: $green;
$sbm-button-color-hover: $green-light;
$sbm-button-color-active: $green-light;
$sbm-button-color-disabled: $green-light;

$hamburger-layer-color: $green;
$hamburger-layer-border-radius: px-to-vw-mobile(6px);
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;

$hamburger-layer-width: 14.9px;
$hamburger-layer-height: 1.52067px;
$hamburger-layer-spacing: 4.64px;

$grid-breakpoints: (
    xs: 0,
    sm: 575.99px,
    md: 767.99px,
    lg: 959.99px,
    xl: 1169.99px,
    xxl: 1439.99px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 920px,
    xl: 1140px,
    xxl: 1320px
);

//$grid-columns: 24 !default;