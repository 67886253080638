html, body {
	font-family: 'SB Sans Display';
	font-style: normal;
	font-weight: 400;
	/*font-size: 22px;
	line-height: 28px;
	font-size: 1.528vw;
	line-height: 128%;*/
	font-size: px-to-vw(22px);
	line-height: px-to-percentage(28px);
	color: $standart-text-color;
	background-color: $green;
	background-image: url('../images/bg.jpg');
	background-size: 100% 100%;
    background-attachment: fixed;
	background-repeat: repeat-y;
	min-height: 100vh;
}

html.index,
body.index {
	background: $white;
}

html.faq,
body.faq {
	background-size: 100% auto;
    background-attachment: local;
	background-image: url('../images/bg-faq.jpg');
}

html.contacts,
body.contacts {
	background-size: 100% auto;
    background-attachment: local;
	background-image: url('../images/bg-contacts.jpg');
}

html.winners,
body.winners {
	background-size: 100% auto;
    background-attachment: local;
	background-image: url('../images/bg-winners.jpg');
}

body:not(.index) {
	padding-top: px-to-vw(85px);
}

::selection {
    background: $black;
    color: $white;
}

::-webkit-selection {
    background: $black;
    color: $white;
}

::-ms-selection {
    background: $black;
    color: $white;
}

::-o-selection {
    background: $black;
    color: $white;
}

::-moz-selection {
    background: $black;
    color: $white;
}

.frontend {

	h1, .h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5,
	h6, .h6 {
		margin-bottom: 0;
	}

	h1, .h1 {
		font-weight: 600;
		/*font-size: 84px;		
		font-size: 5.833vw;*/
		font-size: px-to-vw(84px);
		line-height: 90%;
	}

	h2, .h2 {
		font-weight: 400;
		/*font-size: 60px;
		font-size: 4.167vw;*/
		font-size: px-to-vw(60px);
		line-height: 110%;
	}

	h3, .h3 {
		font-weight: 400;
		/*font-size: 48px;
		font-size: 3.333vw;*/
		font-size: px-to-vw(48px);
		line-height: 110%;
	}

	h4, .h4 {
		font-weight: 400;
		/*font-size: 31px;
		line-height: 39px;
		font-size: 2.153vw;
		line-height: 243.75%;*/
		font-size: px-to-vw(31px);
		line-height: px-to-percentage(39px);
	}

	h5, .h5 {
	}

	h6, .h6 {
	}

	p {
		font-weight: 400;
		/*font-size: 22px;
		line-height: 28px;
		font-size: 1.528vw;
		line-height: 128%;*/
		font-size: px-to-vw(22px);
		line-height: px-to-percentage(28px);
	}

	.font-md {
		font-style: normal;
		font-weight: 400;
		/*font-size: 26px;
		font-size: 1.806vw;*/
		font-size: px-to-vw(26px);
		line-height: 130%;
	}

	.font-bold {
		font-weight: 600;
		/*font-size: 24px;
		line-height: 30px;
		font-size: 1.667vw;*/
		font-size: px-to-vw(24px);
		line-height: 187.5%;
	}

	.font-small {
		font-weight: 400;
		/*font-size: 18px;
		line-height: 23px;
		font-size: 1.250vw;*/
		font-size: px-to-vw(18px);		
		line-height: 143.75%;
	}

	b, strong {
		font-weight: 600;
	}

	.main {

	}

	.sbs-button {

		display: inline-block;
		padding: px-to-vw(31px) px-to-vw(255px);
		text-align: center;
		outline: none;
		border: none;
		text-decoration: none;
		border-radius: px-to-vw(50px);
		font-weight: 400;
		/*font-size: 31px;
		font-size: 2.153vw;*/		
		font-size: px-to-vw(31px);
		line-height: 130%;
		background-color: $sbm-button-color;
		color: $white;
		transition: background .25s ease-in;

		&:hover,
		&:active {
			background-color: $sbm-button-color-hover;
		}

	}
	
	.select2-container--default .select2-selection--single {
        background-color: $white;
        border: none;
        border-radius: px-to-vw(50px);
    }
    
    .select2-container .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: px-to-vw(90px);
        user-select: none;
        -webkit-user-select: none;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        display: block;
        padding-left: 20px;
        padding-right: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        white-space: nowrap;
		font-style: normal;
		font-weight: 400;
		font-size: px-to-vw(26px);
		line-height: 130%;
        line-height: 1;
        color: $gray-dark;
		padding: px-to-vw(36.5px) px-to-vw(90px) px-to-vw(31.5px) px-to-vw(38px);
    }

    .select2-results__option {

        white-space: nowrap;
        font-style: normal;
        font-weight: 400;
		font-size: px-to-vw(26px);
		line-height: 130%;
        color: $gray-dark;
        background-color: transparent;
        user-select: none;
        -webkit-user-select: none;
        padding: px-to-vw(14px) px-to-vw(10px) px-to-vw(10px);
		border-bottom: px-to-vw(2px) solid $gray-light;

		&:last-child {
			border-bottom: 0;
		}

    }
    
    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
        background-color: transparent;
        color: $gray-dark;
    }
    
    .select2-container--default .select2-results__option--selected {
        background-color: transparent;
        color: $gray-dark;
    }
    
    .select2-dropdown {
        border: none;
        background-color: transparent;
    }

	.select2-container--open .select2-dropdown--below,
	.select2-container--open .select2-dropdown--above {
		padding: px-to-vw(25px) px-to-vw(33.58px);
        border-radius: px-to-vw(50px);
	}
    
    .select2-container--open .select2-dropdown--below,
    .select2-container--open .select2-dropdown--below .select2-results,
    .select2-container--open .select2-dropdown--below .select2-results__options {
        border: none;
		background-color: $white;
		top: px-to-vw(3px);
    }
    
    .select2-container--open .select2-dropdown--above,
    .select2-container--open .select2-dropdown--above .select2-results,
    .select2-container--open .select2-dropdown--above .select2-results__options {
        border: none;
		background-color: $white;
    }
    
    .select2-container--default .select2-results > .select2-results__options {
        max-height: 250px;
        /*overflow: hidden;*/
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {

        height: px-to-vw(16.7px);
        width: px-to-vw(32.34px);
        position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: px-to-vw(43px);

        & b {
			position: static;
			display: block;
            border: none;
            margin: 0;
            width: px-to-vw(32.34px);
			height: px-to-vw(16.7px);		
			background: url('../img/sprite.svg#sprite-accordion-arrow-view') no-repeat;
        }

    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
        transform: rotate(180deg) translateY(-50%);
		transform-origin: top center;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: $standart-text-color;
    }

	.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
		border-bottom-left-radius: px-to-vw(50px);
    	border-bottom-right-radius: px-to-vw(50px);
	}

    .nicescroll-rails.nicescroll-rails-vr {
        right: px-to-vw(20px) !important;
        left: auto !important;
    }

}

@media screen and (max-width: 960px) {

	html.contacts,
	body.contacts {
		background-size: 100% 100%;
	}

	html.faq,
	body.faq {
		background-size: 100% 100%;
		background-image: url('../images/bg-mobile.jpg');
	}

	html.winners,
	body.winners {
		background-size: 100% 100%;
		background-image: url('../images/bg-mobile.jpg');
	}

	.body--nav-show {
		overflow: hidden;
	}

	body:not(.index) {
		padding-top: px-to-vw-mobile(54px);
	}

	html, body {
		font-weight: 400;
		font-size: px-to-vw-mobile(16px);
		line-height: px-to-vw-mobile(20px);
		color: $standart-text-color-mobile;
	}

	.frontend {

		h1, .h1 {
			font-weight: 600;
			font-size: px-to-vw-mobile(40px);
			line-height: 90%;
		}
	
		h3, .h3 {
			font-weight: 400;
			font-size: px-to-vw-mobile(24px);
			line-height: 120%;
		}
	
		h4, .h4 {
			font-weight: 400;
			font-size: px-to-vw-mobile(18px);
			line-height: 110%;
		}
	
		p {
			font-weight: 400;
			font-size: px-to-vw-mobile(16px);
			line-height: 20px;
		}
	
		.font-md {
			font-weight: 400;
			font-size: px-to-vw-mobile(18px);
			line-height: 130%;
		}
	
		.font-bold {
			font-weight: 600;
			font-size: px-to-vw-mobile(16px);
			line-height: 20px;
		}
	
		.font-small {
			font-weight: 400;
			font-size: px-to-vw-mobile(14px);
			line-height: px-to-vw-mobile(18px);
		}

		.sbs-button {	
			padding: px-to-vw-mobile(14.3px) px-to-vw-mobile(116.5px) px-to-vw-mobile(12.3px);
			border-radius: px-to-vw-mobile(25px);	
			font-size: px-to-vw-mobile(18px);	
		}
	
		.select2-container--default .select2-selection--single {
			border-radius: px-to-vw-mobile(25px);
		}
		
		.select2-container .select2-selection--single {
			height: px-to-vw-mobile(50px);
		}
	
		.select2-container .select2-selection--single .select2-selection__rendered {
			padding-left: 20px;
			padding-right: 44px;
		}
	
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			font-size: px-to-vw-mobile(16px);
			line-height: px-to-vw-mobile(20px);
			padding: px-to-vw-mobile(17px) px-to-vw-mobile(50px) px-to-vw-mobile(10px) px-to-vw-mobile(21.48px);
		}
	
		.select2-results__option {

			font-size: px-to-vw-mobile(16px);
			line-height: px-to-vw-mobile(20px);
			padding: px-to-vw-mobile(14px) px-to-vw-mobile(10px) px-to-vw-mobile(10px);
			border-bottom: px-to-vw-mobile(2px) solid $gray-light;
	
			&:last-child {
				border-bottom: 0;
			}
	
		}
	
		.select2-container--open .select2-dropdown--below,
		.select2-container--open .select2-dropdown--above {
			padding: px-to-vw-mobile(10px) px-to-vw-mobile(18px);
			border-radius: px-to-vw-mobile(25px);
		}
		
		.select2-container--open .select2-dropdown--below,
		.select2-container--open .select2-dropdown--below .select2-results,
		.select2-container--open .select2-dropdown--below .select2-results__options {
			top: px-to-vw-mobile(3px);
		}
		
		.select2-container--default .select2-results > .select2-results__options {
			max-height: 250px;
			/*overflow: hidden;*/
		}
	
		.select2-container--default .select2-selection--single .select2-selection__arrow {
	
			width: px-to-vw-mobile(13.86px);
			height: px-to-vw-mobile(6.93px);
			right: px-to-vw-mobile(22.14px);
	
			& b {
				width: px-to-vw-mobile(13.86px);
				height: px-to-vw-mobile(6.93px);	
			}
	
		}
	
		.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
			border-bottom-left-radius: px-to-vw-mobile(25px);
			border-bottom-right-radius: px-to-vw-mobile(25px);
		}
	
	}

}
