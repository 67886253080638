.header {

    height: px-to-vw(85px);
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;

    &.--fixed {
        position: fixed;
        

    }

    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        opacity: 0;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=0 );
        transition: all 0.5s linear;
    }

    &.--fixed &__gradient {
        opacity: 1;        
    }

    &__logo {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        height: px-to-vw(85px);
        position: relative;
        z-index: 200;
        /*height: px-to-vw(math.div(85, 2) * 1px);*/

        & > a {
            display: inline-block;
            width: px-to-vw(463px);
            height: px-to-vw(47px);
            background: url('../img/sprite.svg#sprite-logo-co-brend-view') no-repeat;
        }

    }

    &__nav {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        height: px-to-vw(85px);
        /*height: px-to-vw(math.div(85, 2) * 1px);*/
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 200;

        &-item {

            margin-right: px-to-vw(10px);

            &:last-child {
                margin-right: 0;
            }

            & > a {

                font-style: normal;
                font-weight: 600;
                font-size: px-to-vw(18px);
                line-height: px-to-percentage(23px);
                color: $white;
                text-decoration: none;
                display: block;
                padding: px-to-vw(12px) px-to-vw(14px) px-to-vw(7px);
                border-radius: px-to-vw(50px);
                border: solid px-to-vw(1px) transparent;

                &:hover {
                    border-color: $white;
                }

            }

            &.--active {

                & > a,
                & > a:hover {
                    border-color: $white;
                    color: $green;
                    background-color: $white;
                    cursor: default;
                }

            }

        }

    }

}

@media screen and (max-width: 960px) {

    .header {

        height: px-to-vw-mobile(54px);

        &__logo {
    
            height: px-to-vw-mobile(27.6px);
    
            & > a {
                width: px-to-vw-mobile(275px);
                height: px-to-vw-mobile(27.6px);
            }
    
        }

        &__nav {
            display: none;
        }

        &__hamburger {

            cursor: pointer;
            position: relative;
            z-index: 100;
            width: px-to-vw-mobile(34.51px);
            height: px-to-vw-mobile(34.51px);
            background-color: $white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & .hamburger-box {
                width: px-to-vw-mobile(14.9px);
            }

            & .hamburger-inner,
            & .hamburger-inner::before,
            & .hamburger-inner::after {
                width: px-to-vw-mobile(14.9px);
                height: px-to-vw-mobile(1.52067px);
            }

            .hamburger-inner::before {
                top: px-to-vw-mobile(-4.64px);
            }

            .hamburger-inner::after {
                top: px-to-vw-mobile(4.64px);
            }

            &.hamburger--squeeze.is-active .hamburger-inner::after {
                top: 0;
            }

        }

    }

    .--nav-show .header {

        height: 100vh;
        background: url('../images/bg-mobile-menu.png') no-repeat;
        background-size: cover;
        align-items: flex-start !important;
        justify-content: flex-start !important;

        &__gradient {
            display: none;
        }

        &__logo {
            margin-top: px-to-vw-mobile(13px);
        }

        &__hamburger {
            margin-top: px-to-vw-mobile(10px);
        }

        &__nav {
            
            margin-top: px-to-vw-mobile(-27px);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            height: calc(100vh - px-to-vw-mobile(90px)); 
            padding: px-to-vw-mobile(24.05px) px-to-vw-mobile(16.19px);
            background-color: $white;
            border-radius: px-to-vw-mobile(25px);

            &-item {
    
                margin-right: 0;
                margin-bottom: px-to-vw-mobile(17.19px);
    
                &:last-child {
                    margin-bottom: 0; 
                }
    
                & > a {
    
                    font-style: normal;
                    font-weight: 600;
                    font-size: px-to-vw-mobile(27px);
                    line-height: px-to-percentage-mobile(34px);
                    color: $green;
                    text-decoration: none;
                    display: inline-block;
                    padding: 0;
                    border-radius: 0;
                    border: none;
    
                    &:hover {
                        border: none;
                    }
    
                }
    
                &.--active {
    
                    & > a,
                    & > a:hover {
                        border: none;
                        color: $green;
                        background-color: transparent;
                    }
    
                }
    
            }

        }

    }

}