.faq {
    
    &__title {
        padding: px-to-vw(37px) px-to-vw(33px) px-to-vw(238px) px-to-vw(33px);
        border: px-to-vw(2px) solid $white;
        border-radius: px-to-vw(50px);
        margin-bottom: px-to-vw(2px);
        color: $white
    }

    &__feedback {

        display: block;
        padding: px-to-vw(72.5px) px-to-vw(33px) px-to-vw(72.5px) px-to-vw(33px);
        border: px-to-vw(2px) solid #FFFFFF;
        border-radius: px-to-vw(150px);
        margin-bottom: px-to-vw(70px);
        text-decoration: none;

        &-text {
            display: block;
            max-width: px-to-vw(814px);
            margin: 0 auto;
            color: $white
        }

    }

    & .accordion {

		&-button {

			padding: px-to-vw(47.5px) px-to-vw(135.56px) px-to-vw(39.5px) px-to-vw(50px);
            border-radius: px-to-vw(50px);
            -webkit-transition: none;
            transition: none;

			&:after {
				width: px-to-vw(32.34px);
				height: px-to-vw(16.7px);
				background: url('../img/sprite.svg#sprite-accordion-arrow-view') no-repeat;
				position: absolute;
				right: px-to-vw(51.56px);
			}
			
			&:focus {
				box-shadow: none;
			}

			&:not(.collapsed) {
				box-shadow: none;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                background-color: $white;
                color: $green;
			}

		}

		&-item {

			background-color: transparent;
			border: none;
            margin-bottom: px-to-vw(2px);

            &:first-of-type .accordion-button {
                border-top-left-radius: px-to-vw(50px);
                border-top-right-radius: px-to-vw(50px);
            }

            &:last-of-type .accordion-button {
                border-bottom-left-radius: px-to-vw(50px);
                border-bottom-right-radius: px-to-vw(50px);
            }

		}

		& .accordion-collapse.collapsing {
            -webkit-transition: none;
			transition: none;
			display: none;
		}

		&-body {

			/*padding: 0 0 48px 0;*/
			padding: px-to-vw(40.88px) px-to-vw(56.56px) px-to-vw(70.12px) px-to-vw(50px);
			border-top: px-to-vw(1px) solid $gray-light;
            background-color: $white;
            border-bottom-left-radius: px-to-vw(50px);
            border-bottom-right-radius: px-to-vw(50px);

		}

	}

}

@media screen and (max-width: 960px) {
    
    .faq {
        
        &__title {
            padding: px-to-vw-mobile(18px) px-to-vw-mobile(37px) px-to-vw-mobile(140px) px-to-vw-mobile(16px);
            border: px-to-vw-mobile(1px) solid $white;
            border-radius: px-to-vw-mobile(25px);
            margin-bottom: px-to-vw-mobile(2px);
            color: $white
        }

        &__feedback {

            padding: px-to-vw-mobile(36px) px-to-vw-mobile(39.1px) px-to-vw-mobile(35.99px) px-to-vw-mobile(36.55px);
            border: px-to-vw-mobile(1px) solid #FFFFFF;
            border-radius: px-to-vw-mobile(100px);
            margin-bottom: px-to-vw-mobile(126px);

            &-text {
                max-width: px-to-vw-mobile(265px);
            }

        }

        & .accordion {

            &-button {

                padding: px-to-vw-mobile(14px) px-to-vw-mobile(47.5px) px-to-vw-mobile(17px) px-to-vw-mobile(16px);
                border-radius: px-to-vw-mobile(25px);

                &:after {
                    width: px-to-vw-mobile(16.17px);
                    height: px-to-vw-mobile(8.08px);
                    position: absolute;
                    right: px-to-vw-mobile(22px);
                }

            }

            &-item {

                background-color: transparent;
                border: none;
                margin-bottom: px-to-vw-mobile(2px);

                &:first-of-type .accordion-button {
                    border-top-left-radius: px-to-vw-mobile(25px);
                    border-top-right-radius: px-to-vw-mobile(25px);
                }

                &:last-of-type .accordion-button {
                    border-bottom-left-radius: px-to-vw-mobile(25px);
                    border-bottom-right-radius: px-to-vw-mobile(25px);
                }

            }

            &-body {

                /*padding: 0 0 48px 0;*/
                padding: px-to-vw-mobile(14.5px) px-to-vw-mobile(24.16px) px-to-vw-mobile(22.5px) px-to-vw-mobile(16px);
                border-top: px-to-vw-mobile(2px) solid $gray-light;
                border-bottom-left-radius: px-to-vw-mobile(25px);
                border-bottom-right-radius: px-to-vw-mobile(25px);

            }

        }

    }

}