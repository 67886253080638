.winners {

    &__filter {

        border: px-to-vw(2px) solid $white;
        border-radius: px-to-vw(50px);
        padding: px-to-vw(37.4px) px-to-vw(32px) px-to-vw(32px);    
        
        &-form {

            & .filter-form {

                &__group {

                    width: px-to-vw(526px);
                    padding-right: px-to-vw(10px);

                    &.--submit {
                        flex: 1;
                        padding-right: 0;
                        width: auto;
                    }

                    & .form-group {

                        position: relative;

                        &__icon {

                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: px-to-vw(29.46px);
                            width: px-to-vw(31.07px);
                            height: px-to-vw(32.09px);
                            background: url('../img/sprite.svg#sprite-input-search-icon-view') no-repeat;
                            z-index: 100;

                            &.--search {



                            }

                        }

                    }
                    
                }

                &__label {
                    margin-bottom: px-to-vw(20px);
                    color: $white;
                }

                &__select {
                    
                }

                &__input {
                    background-color: $white;
                    border-radius: px-to-vw(50px);
                    padding: px-to-vw(31.5px) px-to-vw(79.78px) px-to-vw(24.5px);
                    border: none;
                    outline: none;
                    width: 100%;
                    line-height: 1;
                }

                &__submit {

                    background-color: $green;
                    padding: px-to-vw(31px) /*px-to-vw(67.5px)*/0 px-to-vw(28px);
                    color: $white;
                    font-style: normal;
                    font-weight: 400;
                    font-size: px-to-vw(31px);
                    line-height: 130%;
                    border: none;
                    outline: none;
                    width: 100%;
                    border-radius: px-to-vw(50px);
                    line-height: 1;
                    transition: background 0.25s linear;
                    text-align: center;

                    &:hover {
                        background-color: darken($green, 5%);
                    }

                }

            }

        }
        
    }

    &__title {
        color: $white;
        margin-bottom: px-to-vw(88.5px);
    }

    &__table {

        padding: px-to-vw(28px) px-to-vw(30px) px-to-vw(38px);
        background-color: $white;
        border-radius: px-to-vw(50px);

        .table {

            &__header {

                padding: 0 px-to-vw(24px) px-to-vw(27px);
                border-bottom: px-to-vw(2px) solid $gray-light;
                margin-bottom: px-to-vw(21px);
        
                &-row {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;

                }
    
                &-col {
                    flex: 0 0 25%;
                    text-align: center;  
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 1;
                }
        
            }

            &__body {                

                min-height: px-to-vw(300px);
                position: relative;

                & > .loader {				
                    display: none;
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    z-index: 120;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        -o-transform: translate(-50%, -50%);
                            transform: translate(-50%, -50%);
                }

                &.--loading {

                    & > .loader {				
                        display: block;
                    }			

                }

                & > .no-data {		
                    display: none;
                    text-align: center;
                    color: #FFFFFF;		
                }

                &.--no-data {

                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    & > .no-data {		
                        display: block;			
                    }

                }

                &-row {

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: px-to-vw(20px) px-to-vw(30px);
                    border-radius: px-to-vw(25px);                  

                    &:nth-child(odd) {
                        background-color: $gray-light;
                    }

                }

                &-col {
                    flex: 0 0 25%;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    line-height: 1;
                }
                    
            }

        }

    }

    &__buttons {
        margin-top: px-to-vw(100px);
        margin-bottom: px-to-vw(136px);
        text-align: center;
    }
    
}

@media screen and (max-width: 960px) {

    .winners {

        &__filter {
    
            border: px-to-vw-mobile(1px) solid $white;
            border-radius: px-to-vw-mobile(30px);
            padding: px-to-vw-mobile(18.38px) px-to-vw-mobile(18.87px) px-to-vw-mobile(16px) px-to-vw-mobile(16.19px);   
            margin-bottom: px-to-vw-mobile(2px); 
            
            &-form {
    
                & .filter-form {
    
                    &__group {
    
                        width: 100%;
                        padding-right: 0;
                        margin-bottom: px-to-vw-mobile(10px);
    
                        &.--submit {
                            width: 100%;
                            flex: 0 0 100%;
                            margin-bottom: 0;
                        }
    
                        & .form-group {
    
                            position: relative;
    
                            &__icon {
    
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: px-to-vw-mobile(19.34px);
                                width: px-to-vw-mobile(13.32px);
                                height: px-to-vw-mobile(13.75px);
                                background: url('../img/sprite.svg#sprite-input-search-icon-view') no-repeat;
                                z-index: 100;
    
                                &.--search {
    
    
    
                                }
    
                            }
    
                        }
                        
                    }
    
                    &__label {
                        margin-bottom: px-to-vw-mobile(10px);
                    }
    
                    &__input {
                        border-radius: px-to-vw-mobile(50px);
                        padding: px-to-vw-mobile(17px) px-to-vw-mobile(44.29px) px-to-vw-mobile(10px);
                    }
    
                    &__submit {    
                        background-color: $green;
                        padding: px-to-vw-mobile(14.5px) 0 px-to-vw-mobile(11.5px);
                        font-size: px-to-vw-mobile(18px);
                        line-height: px-to-vw-mobile(23.4px);
                        border-radius: px-to-vw-mobile(50px);    
                    }
    
                }
    
            }
            
        }
    
        &__title {
            margin-bottom: px-to-vw-mobile(91.62px);
        }
    
        &__table {

            padding: 0;
            background-color: transparent;
            border-radius: none;
    
            &-item {

                margin-bottom: px-to-vw-mobile(2px);    
                padding: px-to-vw-mobile(20px) px-to-vw-mobile(20px) px-to-vw-mobile(10px);
                background-color: $white;
                border-radius: px-to-vw-mobile(25px);

                &:last-child {
                    margin-bottom: 0;
                }

                & .table-item {

                    &__line {

                        border-bottom: px-to-vw(2px) solid $gray-light;
                        padding-bottom: px-to-vw(11px);
                        margin-bottom: px-to-vw(8px);
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-start;

                        &:last-child {
                            border-bottom: none;
                            margin-bottom: 0;
                        }

                        &-left {
                            flex: 0 0 50%;
                        }

                        &-right {
                            flex: 0 0 50%;
                            text-align: right;  
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;                            
                        }

                    }

                }

            }
    
        }
    
        &__buttons {

            margin-top: px-to-vw-mobile(2px);
            margin-bottom: px-to-vw-mobile(136px);
            text-align: center;

            & > a {                
			    padding: px-to-vw-mobile(18.5px) px-to-vw-mobile(105px) px-to-vw-mobile(18.5px) !important;
                border-radius: px-to-vw-mobile(50px) !important;
                width: 100%;
            }

        }
        
    }

}