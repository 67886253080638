@use "sass:math";

.home {

    &__left {
        flex: 0 0 50%;
        padding-right: px-to-vw(1px);
        margin-bottom: px-to-vw(46px);
    }

    &__right {
        flex: 0 0 50%;
        padding-left: px-to-vw(1px);
        margin-bottom: px-to-vw(46px);
    }

    &__logo {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        height: px-to-vw(85px);
        /*height: px-to-vw(math.div(85, 2) * 1px);*/

        & > a {
            display: inline-block;
            width: px-to-vw(463px);
            height: px-to-vw(47px);
            background: url('../img/sprite.svg#sprite-logo-co-brend-view') no-repeat;
        }

    }

    &__nav {

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        height: px-to-vw(85px);
        /*height: px-to-vw(math.div(85, 2) * 1px);*/
        margin: 0;
        padding: 0;
        list-style: none;

        &-item {

            margin-right: px-to-vw(10px);

            &:last-child {
                margin-right: 0;
            }

            & > a {

                font-style: normal;
                font-weight: 600;
                font-size: px-to-vw(18px);
                line-height: px-to-percentage(23px);
                color: $white;
                text-decoration: none;
                display: block;
                padding: px-to-vw(11px) px-to-vw(14px) px-to-vw(8px);
                border-radius: px-to-vw(50px);
                border: solid px-to-vw(1px) transparent;

                &:hover {
                    border-color: $white;
                }

            }

            &.--active {

                & > a,
                & > a:hover {
                    border-color: $white;
                    color: $green;
                    background-color: $white;
                    cursor: default;
                }

            }

        }

    }

    &__sticky {
        position: sticky;
        top: px-to-vw(85px);
    }
    
    &__block {
        background-color: $white;
        padding: px-to-vw(37px) px-to-vw(22px) px-to-vw(31px) px-to-vw(35px);
        border-radius: px-to-vw(50px);
        margin-bottom: px-to-vw(2px);
    }

    &__block-1 {

        display: flex;
        flex-direction: column;
        /*min-height: 661px;*/
        height: px-to-vw(math.div(695, math.div(695, 661)) * 1px);
        color: $green;
        margin-bottom: px-to-vw(40px);

        & h1 {
            margin-bottom: px-to-vw(35px);
        }

        .home-block-1 {

            &__top-text {
                color: $green;
                margin-bottom: px-to-vw(300px);
            }

            &__bottom-text {
                margin-top: auto;
                color: $green;
            }

        }

    }

    &__block-2 {

        display: flex;
        flex-direction: column;
        /*min-height: 553px;*/
        margin-bottom: px-to-vw(2px);
        border-radius: 0;
        padding: 0;
        background-color: transparent;

        & h3 {
        }

        .home-block-2 {

            &__top {
                border-top-left-radius: px-to-vw(50px);
                border-top-right-radius: px-to-vw(50px);
                padding: px-to-vw(36px) px-to-vw(103px) px-to-vw(86px) px-to-vw(30px);
                border-bottom: solid  px-to-vw(1px) $gray-light;
                background-color: $white;
            }

            &__bottom {                
                border-bottom-left-radius: px-to-vw(50px);
                border-bottom-right-radius: px-to-vw(50px);
                padding: px-to-vw(28.5px) 0 px-to-vw(26.45px) 0;
                background-color: $white;
            }

            &__countdown {

                & .home-block-2-countdown {

                    &__title {
                        margin-bottom: px-to-vw(17px);
                    }

                    &__list {

                        &-item {

                            width: px-to-vw(126px);
                            height: px-to-vw(115px);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-right: px-to-vw(23.33px);
                            border-radius: px-to-vw(25px);
                            background-color: $gray-light;
                            position: relative;

                            &:before,
                            &:after {
                                content: '';
                                display: block;
                                width: px-to-vw(7px);
                                height: px-to-vw(7px);
                                position: absolute;
                                z-index: 100;
                                background-color: $gray-dark;
                                top: 50%;
                                right: px-to-vw(-15px);
                                transform: translateY(-50%);
                                border-radius: 100%;
                            }

                            &:before {
                                margin-top: px-to-vw(-9px);
                            }

                            &:after {
                                margin-top: px-to-vw(9px);
                            }

                            &:last-child {

                                margin-right: none;

                                &:before,
                                &:after {
                                    display: none;
                                }

                            }

                            & > .num {
                                color: $gray-dark;
                                font-style: normal;
                                font-weight: 400;
                                font-size: px-to-vw(49.2793px);
                                line-height: 90%;
                                position: relative;
                                top: px-to-vw(8px);
                            }

                            & > .text {
                                color: $gray-dark;   
                                font-style: normal;
                                font-weight: 400;
                                font-size: px-to-vw(26px);
                                line-height: 130%;                             
                            }

                        }

                    }

                }

            }

        }

    }    

    &__block-3 {

        display: flex;
        flex-direction: column;
        /*min-height: 279px;*/
        margin-bottom: px-to-vw(2px);
        height: px-to-vw(math.div(695, math.div(695, 279)) * 1px);

        & .home-block-3 {

            &__num {

                width: px-to-vw(58px);
                height: px-to-vw(58px);
                border: px-to-vw(2px) solid $green;
                border-radius: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                position: relative;
                top: px-to-vw(-17px);
                left: px-to-vw(-15px);
                z-index: 100;
                /*margin-bottom: 105px;*/

                & span {
                    font-style: normal;
                    font-weight: 400;
                    /*line-height: 130%;*/
                    line-height: 0;
                    font-size: px-to-vw(32px);
                    line-height: px-to-vw(61px);
                    color: $green;
                }

            }

            &__text {
                margin-top: auto;
                max-width: 75%;
            }


        }

    }

    &__block-4 {
        
        padding: px-to-vw(33px) px-to-vw(60px) px-to-vw(40px) px-to-vw(60px);
        border: solid px-to-vw(1px) $white;
        background-color: transparent;
        border-radius: px-to-vw(150px);
        text-align: center;
        /*min-height: 283px;*/

        & .home-block-4 {

            &__title {
                color: $white;
                /*margin-bottom: 17px;*/
                margin-bottom: px-to-vw(8px);
            }

            &__text {
                color: $white;
            }

        }
        
    }

    &__block-5 {
        
        padding: px-to-vw(37px) px-to-vw(40px) px-to-vw(44px) px-to-vw(35px);        
        height: px-to-vw(math.div(695, math.div(695, 652)) * 1px);
        /*min-height: 652px;*/

        & .home-block-5 {

            &__title {
                margin-bottom: px-to-vw(29px);
                width: 85.5%;
            }

            &__text {
                width: 85.5%;
            }

            &__logo {

                &-icon {
                    width: px-to-vw(26px);
                    height: px-to-vw(40px);
                    background: url('../img/sprite.svg#sprite-samolet-logo-icon-blue-view') no-repeat;
                }

                &-text {
                    width: px-to-vw(235px);
                    height: px-to-vw(32px);
                    background: url('../img/sprite.svg#sprite-samolet-logo-text-blue-view') no-repeat;
                }

            }

        }
        
    }

    &__block-6 {
        
        padding: 0;
        background-color: transparent;
        height: px-to-vw(math.div(695, math.div(695, 652)) * 1px);
        /*min-height: 652px;*/

        & .home-block-6 {

            &__picture {
                
                height: 100%;
                position: relative;
                border-radius: px-to-vw(50px);
                overflow: hidden;

                & picture,
                & img {
                    display: block;
                    height: 100%;
                    width: 100%;
                    /*height: calc(661px - 100px);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    z-index: 100;
                    zoom: 1.1;*/

                }

            }

        }
        
    }

    &__present-block {

        display: flex;
        flex-direction: column;
        height: px-to-vw(math.div(695, math.div(695, 661)) * 1px);

        .present-block {

            &__picture {
                
                position: relative;
                border-radius: px-to-vw(50px);
                overflow: hidden;
                flex: 1;

                & picture,
                & img {
                    display: block;                        
                    width: auto;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    /*height: calc(661px - 100px);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    z-index: 100;
                    zoom: 1.1;*/

                }

            }

            &__button {

                & > a {
                    width: 100%;
                    padding: px-to-vw(30px) 0;
                }

            }

        }

    }

    &__winners {

        padding: px-to-vw(56px) px-to-vw(59px) px-to-vw(51px) px-to-vw(170px);
        background-color: $white;
        border-radius: px-to-vw(327px);
        margin-bottom: px-to-vw(63px);

        &-left {
            flex: 1;
        }

        &-right {

            width: px-to-vw(404px);
            height: px-to-vw(404px);
            position: relative;
            border-radius: 100%;
            overflow: hidden;

            & picture,
            & img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        &-title {
            color: $green;
            margin-bottom: px-to-vw(20px) !important;
        }

        &-button {
            
            & > a {
                padding: px-to-vw(30px) px-to-vw(142px) !important;
            }

        }

        &-content {

            position: absolute;
            /*top: px-to-vw(95px);*/
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 100;
            text-align: center;

            & .winners-content {

                &__winner-date,
                &__winner-name {
                    color: $white;
                }

                &__button {

                    margin-top: px-to-vw(30px);

                    & > a {

                        padding: px-to-vw(11px) px-to-vw(43px) px-to-vw(7px);
                        display: inline-block;
                        text-decoration: none;
                        font-weight: 400;
                        font-size: px-to-vw(18px);
                        line-height: px-to-percentage(22px);
                        color: $green;
                        background-color: $white;
                        transition: all .25s linear;
                        border-radius: px-to-vw(148px);

                        &:hover {
                            color: $white;
                            background-color: $green;
                        }

                    }


                }

            }

        }

    }

    &__prizes {

        margin-bottom: px-to-vw(64.5px);

        &-top {
            margin-bottom: px-to-vw(33px);
        }

        &-title {
            color: $white;
        }

        &-nav {       

            & .prizes-nav {

                &__prev,
                &__next {
    
                    width: px-to-vw(80px);
                    height: px-to-vw(80px);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    margin: 0;
                    background-color: $white;
                    cursor: pointer;
    
                    &:hover {
                        background-color: $white;
                    }
    
                    &:before {
                        content: '';
                        display: block;
                        width: px-to-vw(24.04px);
                        height: px-to-vw(20.39px);
                    }
    
                }
    
                &__prev {

                    margin-right: px-to-vw(6px);
    
                    &:before {
                        background: url('../img/sprite.svg#sprite-slider-arrow-prev-view') no-repeat;
                    }
    
                }
    
                &__next {

                    &:before {
                        background: url('../img/sprite.svg#sprite-slider-arrow-next-view') no-repeat;          
                    }
    
                }

            }

        }

        &-list {

            &.owl-carousel {
                width: auto;
                display: block !important;
            }

            & .owl-stage {
                display: flex;
                align-items: stretch;
            }

            & .prizes-list {

                &__item {

                    width: calc(px-to-vw(407.75px) + px-to-vw(4px));
                    padding-right: px-to-vw(4px);

                    &-picture {
    
                        width: px-to-vw(407.75px);
                        height: px-to-vw(292px);
                        border-radius: px-to-vw(50px);
                        background-color: $white;
                        margin-bottom: px-to-vw(2px);

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;                        
    
                        & picture,
                        & img {
                            display: block;
                            height: auto;
                        }

                        &.--smart-watch {    
                            & picture,
                            & img {
                                width: px-to-vw(210px);
                            }
                        }

                        &.--smartphone {    
                            & picture,
                            & img {
                                width: px-to-vw(381px);
                            }
                        }

                        &.--robot-vacuum-cleaner {    
                            & picture,
                            & img {
                                width: px-to-vw(243px);
                            }
                        }

                        &.--smart-speaker {    
                            & picture,
                            & img {
                                width: px-to-vw(153px);
                            }
                        }

                        &.--air-cleaner {    
                            & picture,
                            & img {
                                width: px-to-vw(74px);
                            }
                        }

                        &.--certificate {    
                            & picture,
                            & img {
                                width: px-to-vw(250px);
                            }
                        }
    
                    }

                    &-name {
                        width: 100%;
                        padding: px-to-vw(27px) 0 px-to-vw(27px) 0;
                        border: px-to-vw(2px) solid $white;
                        border-radius: px-to-vw(50px);
                        text-align: center;
                        color: $white;
                    }

                }

            }

        }

    }

}

@media screen and (max-width: 960px) {

    .home {

        &__left {

            flex: 0 0 100%;
            width: 100%;
            padding-right: 0px;
            margin-bottom: px-to-vw-mobile(33.62px);

            &.--mb-1 {
                margin-bottom: px-to-vw-mobile(2px);
            }

        }
    
        &__right {
            flex: 0 0 100%;
            width: 100%;
            padding-left: 0px;
        }
    
        &__block {
            padding: px-to-vw-mobile(18.38px) px-to-vw-mobile(7.76px) px-to-vw-mobile(18.39px) px-to-vw-mobile(14.19px);
            border-radius: px-to-vw-mobile(25px);
            margin-bottom: px-to-vw-mobile(2px);
        }

        &__block-1 {
    
            display: flex;
            flex-direction: column;
            height: px-to-vw-mobile(math.div(340, math.div(340, 275.53)) * 1px);
            color: $green;
            margin-bottom: 0;
    
            & h1 {
                margin-bottom: px-to-vw-mobile(17.19px);
            }
    
            .home-block-1 {
    
                &__top-text {
                    color: $green;
                    margin-bottom: px-to-vw-mobile(87.56px);                    
                    font-size: px-to-vw-mobile(16px);
                    line-height: px-to-vw-mobile(20px);
                }
    
            }
    
        }

        &__block-2 {
    
            margin-bottom: px-to-vw-mobilr(2px);
            border-radius: 0;
            padding: 0;
    
            & h3 {
            }
    
            .home-block-2 {
    
                &__top {
                    border-top-left-radius: px-to-vw-mobile(25px);
                    border-top-right-radius: px-to-vw-mobile(25px);
                    padding: px-to-vw-mobile(23px) px-to-vw-mobile(1px) px-to-vw-mobile(68.58px) px-to-vw-mobile(17px);
                }
    
                &__bottom {                
                    border-bottom-left-radius: px-to-vw-mobile(25px);
                    border-bottom-right-radius: px-to-vw-mobile(25px);
                    padding: px-to-vw-mobile(26.97px) 0 px-to-vw-mobile(15.46px) 0;
                }
    
                &__countdown {
    
                    & .home-block-2-countdown {
    
                        &__title {
                            margin-bottom: px-to-vw-mobile(15.45px);
                        }
    
                        &__list {
    
                            &-item {
    
                                width: px-to-vw-mobile(66.27px);
                                height: px-to-vw-mobile(83.54px);
                                margin-right: px-to-vw-mobile(12.43px);
                                border-radius: px-to-vw-mobile(17.2588px);
    
                                &:before,
                                &:after {
                                    width: px-to-vw-mobile(5px);
                                    height: px-to-vw-mobile(5px);
                                    right: px-to-vw-mobile(-9px);
                                }
    
                                &:before {
                                    margin-top: px-to-vw-mobile(-8px);
                                }
    
                                &:after {
                                    margin-top: px-to-vw-mobile(8px);
                                }
    
                                &:last-child {
    
                                    margin-right: 0;
    
                                    &:before,
                                    &:after {
                                        display: none;
                                    }
    
                                }
    
                                & > .num {
                                    font-size: px-to-vw-mobile(29.4679px);
                                    line-height: 120%;
                                    top: px-to-vw-mobile(3px);
                                }
    
                                & > .text {
                                    font-size: px-to-vw-mobile(16px);
                                    line-height: 130%;                             
                                }
    
                            }
    
                        }
    
                    }
    
                }
    
            }
    
        }   

        &__block-3 {
    
            margin-bottom: px-to-vw-mobile(2px);
            height: px-to-vw-mobile(math.div(340, math.div(340, 166.46)) * 1px);
    
            & .home-block-3 {
    
                &__num {
    
                    width: px-to-vw-mobile(28px);
                    height: px-to-vw-mobile(28px);
                    border: px-to-vw-mobile(2px) solid $green;
                    top: px-to-vw-mobile(-6.82px);
                    left: px-to-vw-mobile(-6.82px);
    
                    & span {
                        font-size: px-to-vw-mobile(16px);
                        line-height: px-to-vw-mobile(27px);
                    }
    
                }
    
                &__text {
                    max-width: 100%;
                }
    
    
            }
    
        }

        &__block-4 {
            
            padding: px-to-vw-mobile(18.2px) px-to-vw-mobile(33.66px) px-to-vw-mobile(21.92px) px-to-vw-mobile(29.1px);
            border: solid px-to-vw-mobile(1px) $white;
            border-radius: px-to-vw-mobile(100px);
    
            & .home-block-4 {
    
                &__title {
                    color: $white;
                    /*margin-bottom: 17px;*/
                    margin-bottom: px-to-vw-mobile(13.88px);
                }
    
                &__text {
                    color: $white;
                }
    
            }
            
        }

        &__block-5 {
            
            padding: px-to-vw-mobile(18.09px) px-to-vw-mobile(21.18px) px-to-vw-mobile(21.63px) px-to-vw-mobile(17.57px);        
            height: px-to-vw-mobile(math.div(340, math.div(340, 230)) * 1px);
            /*min-height: 652px;*/
    
            & .home-block-5 {
    
                &__title {
                    margin-bottom: px-to-vw-mobile(20px);
                    width: 100%;
                }
    
                &__text {
                    width: px-to-vw-mobile(285px);
                }
    
                &__logo {
    
                    &-icon {
                        width: px-to-vw-mobile(11.56px);
                        height: px-to-vw-mobile(16.9px);
                    }
    
                    &-text {
                        width: px-to-vw-mobile(115.19px);
                        height: px-to-vw-mobile(15.52px);
                    }
    
                }
    
            }
            
        }

        &__block-6 {
            
            height: px-to-vw-mobile(math.div(340, math.div(340, 306)) * 1px);
            padding: 0;
    
            & .home-block-6 {
    
                &__picture {
                    border-radius: px-to-vw-mobile(25px);    
                }
    
            }
            
        }

        &__present-block {
    
            height: auto;
            height: px-to-vw-mobile(math.div(340, math.div(340, 279)) * 1px);
            margin-bottom: px-to-vw-mobile(32px);
    
            .present-block {
    
                &__picture {
                    
                    position: relative;
                    border-radius: px-to-vw-mobile(34.3793px);
                    overflow: hidden;
                    flex: 1;
    
                    & picture,
                    & img {
    
                    }

                    &-watermark {
                        width: px-to-vw-mobile(115.19px);
                        height: px-to-vw-mobile(15.52px);
                        position: absolute;
                        bottom: px-to-vw-mobile(21.88px);
                        right: px-to-vw-mobile(36.77px);
                        z-index: 100;
                        background: url('../img/sprite.svg#sprite-samolet-watermark-view') no-repeat;
                    }
    
                }
    
                &__button {
    
                    & > a {
                        padding: px-to-vw-mobile(14.3px) px-to-vw-mobile(116.5px) px-to-vw-mobile(12.3px);
                    }
    
                }
    
            }
    
        }

        &__winners {
    
            padding: 0;
            background-color: transparent;
            border-radius: 0;
            margin-bottom: px-to-vw-mobile(26.85px);
    
            &-left {        
                order: 1;
                flex: 0 0 100%;
            }
    
            &-right {   

                order: 0;
                width: px-to-vw-mobile(309px);
                height: px-to-vw-mobile(309px);
                margin: 0 auto;

                & picture,
                & img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

            }

            &-btn {

                padding: px-to-vw-mobile(13.3px) px-to-vw-mobile(64.43px) px-to-vw-mobile(13.3px);
                display: inline-block;
                text-decoration: none;
                font-weight: 400;
                font-size: px-to-vw-mobile(18px);
                line-height: px-to-percentage-mobile(22px);
                color: $green;
                background-color: $white;
                transition: all .25s linear;
                border-radius: px-to-vw(148px);
                margin-bottom: px-to-vw-mobile(35px);

                &:hover {
                    color: $white;
                    background-color: $green;
                }

            }

            &-wrap {
                width: px-to-vw-mobile(309px);
                height: px-to-vw-mobile(math.div(309, math.div(309, 393)) * 1px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: $white;
                border-radius: px-to-vw-mobile(200px);
                padding: 0 px-to-vw-mobile(25.53px);
                margin: 0 auto;
            }
    
            &-title {
                color: $green;
                margin-bottom: px-to-vw-mobile(21px) !important;
            }
    
            &-button {
                
                & > a {
                    padding: px-to-vw-mobile(15.3px) px-to-vw(142px) px-to-vw-mobile(11.3px) !important;
                }
    
            }
    
            &-content {
    
                position: absolute;
                /*top: px-to-vw(95px);*/
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 100;
                text-align: center;
    
                & .winners-content {
    
                    &__winner-date,
                    &__winner-name {
                        color: $white;
                    }                    
    
                }
    
            }
    
        }

        &__prizes {
    
            margin-top: px-to-vw-mobile(20px);
            margin-bottom: px-to-vw-mobile(32px);
    
            &-top {
                margin-bottom: px-to-vw-mobile(10px);
            }
    
            &-nav {     
                
                position: relative;
                top: px-to-vw-mobile(-3px);
    
                & .prizes-nav {
    
                    &__prev,
                    &__next {
        
                        width: px-to-vw-mobile(31.43px);
                        height: px-to-vw-mobile(31.43px);
        
                        &:before {
                            content: '';
                            display: block;
                            width: px-to-vw-mobile(11.81px);
                            height: px-to-vw-mobile(10.01px);
                        }
        
                    }
        
                    &__prev {    
                        margin-right: px-to-vw-mobile(2.85px);        
                    }
    
                }
    
            }
    
            &-list {
    
                & .prizes-list {
    
                    &__item {
    
                        width: calc(px-to-vw-mobile(200px) + px-to-vw-mobile(2px));
                        padding-right: px-to-vw-mobile(2px);
    
                        &-picture {
        
                            width: px-to-vw-mobile(200px);
                            height: px-to-vw-mobile(143.41px);
                            border-radius: px-to-vw-mobile(25px);
                            margin-bottom: px-to-vw-mobile(1px);                     
        
                            & picture,
                            & img {
                                display: block;
                                height: auto;
                            }
    
                            &.--smart-watch {    
                                & picture,
                                & img {
                                    width: px-to-vw-mobile(102.75px);
                                }
                            }
    
                            &.--smartphone {    
                                & picture,
                                & img {
                                    width: px-to-vw-mobile(155px);
                                }
                            }
    
                            &.--robot-vacuum-cleaner {    
                                & picture,
                                & img {
                                    width: px-to-vw-mobile(120px);
                                }
                            }
    
                            &.--smart-speaker {    
                                & picture,
                                & img {
                                    width: px-to-vw-mobile(74px);
                                }
                            }

                            &.--air-cleaner {    
                                & picture,
                                & img {
                                    width: px-to-vw(143px);
                                }
                            }
    
                            &.--certificate {    
                                & picture,
                                & img {
                                    width: px-to-vw-mobile(124px);
                                }
                            }
        
                        }
    
                        &-name {
                            padding: px-to-vw-mobile(13.26px) 0 px-to-vw-mobile(13.26px) 0;
                            border: px-to-vw-mobile(1px) solid $white;
                            border-radius: px-to-vw-mobile(24.5px);
                        }
    
                    }
    
                }
    
            }
    
        }

    }


}