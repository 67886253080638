@import "fonts";

@import "variables";
@import "functions";

//@import "~bootstrap/scss/bootstrap-reboot";
//@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap";
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";
@import "~select2/dist/css/select2.min.css";

@import "_base";
@import "_header";
@import "_footer";
@import "_common";
@import "_home";
@import "_faq";
@import "_winners";
@import "_contacts";