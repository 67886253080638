.contacts {

    &__left {
        flex: 0 0 50%;
        padding-right: px-to-vw(1px);
        margin-bottom: px-to-vw(73px);
    }

    &__right {
        flex: 0 0 50%;
        padding-left: px-to-vw(1px);
        margin-bottom: px-to-vw(73px);
    }

    &__title {
        color: $white;
        height: 100%;
        border: px-to-vw(2px) solid $white;
        border-radius: px-to-vw(50px);
        padding: px-to-vw(37.4px) px-to-vw(32.82px);
    }

    &__block {

        margin-bottom: px-to-vw(2px);
        background-color: $white;
        padding: px-to-vw(30px);
        border-radius: px-to-vw(50px);

        &:last-child {
            margin-bottom: 0;
        }

        &-title {
            margin-bottom: px-to-vw(154px) !important;
        }

        &-email {

            margin-bottom: px-to-vw(4px);
            
            & > a {

                font-style: normal;
                font-weight: 400;
                font-size: px-to-vw(26px);
                line-height: 130%;
                text-decoration: underline;
                color: $standart-text-color;

                &:hover {
                    text-decoration: none;
                }

            }

        }

        &-phone {
            
            & > a {
                
                font-style: normal;
                font-weight: 400;
                font-size: px-to-vw(26px);
                line-height: 130%;
                text-decoration: none;
                color: $standart-text-color;

                &:hover {
                    text-decoration: underline;
                }

            }

        }

    }

}

@media screen and (max-width: 960px) {

    .contacts {

        &__left {
            flex: 0 0 100%;
            padding-right: 0;
            margin-bottom: px-to-vw-mobile(2px);
        }
    
        &__right {
            flex: 0 0 100%;
            padding-left: 0;
            margin-bottom: px-to-vw-mobile(74px);
        }
    
        &__title {
            color: $white;
            height: 100%;
            border: px-to-vw-mobile(1px) solid $white;
            border-radius: px-to-vw-mobile(30px);
            padding: px-to-vw-mobile(18.38px) px-to-vw-mobile(18.87px) px-to-vw-mobile(175.62px) px-to-vw-mobile(16.19px);
        }
    
        &__block {
    
            margin-bottom: px-to-vw-mobile(2px);
            background-color: $white;
            padding: px-to-vw-mobile(20px) px-to-vw-mobile(16px);
            border-radius: px-to-vw-mobile(25px);
    
            &:last-child {
                margin-bottom: 0;
            }
    
            &-title {
                margin-bottom: px-to-vw-mobile(49px) !important;
            }
    
            &-email {
    
                margin-bottom: px-to-vw-mobile(4px);
                
                & > a {    
                    font-size: px-to-vw-mobile(18px);
                    line-height: 110%;    
                }
    
            }
    
            &-phone {
                
                & > a {
                    font-size: px-to-vw-mobile(18px);
                    line-height: 110%;    
                }
    
            }
    
        }
    
    }

}